.leaderboard-card{
    background-color: rgb(243 244 246);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 5px;
}

.leaderboard-card-header{
    padding-left: 40px;
    padding-top: 10px;
}

.leaderboard-card-title{
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    color: rgb(30, 29, 29);
    margin-bottom: 5px;
}
.leaderboard-card-subtitle{
    color: rgb(30, 29, 29);
    font-weight: 500;
    font-size: 18px;
    margin: 0px 0px 5px 0px;
}

.leaderboard-user-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    padding: 10px;
    color: black;
    border: 1px solid rgb(229, 222, 222);
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
}

.initials-badge{
border-radius: 100%;
display: flex;
justify-content: center;
align-items: center;
height: 2.5rem;
width: 2.5rem;
background-color: rgb(250 204 21);
margin: 10px 15px 10px 0px; 
flex-shrink: 0;
font-size: 18px;
}
.count-badge{
border-radius: 100%;
height: 2.5rem;
width: 2.5rem;
border: 2px solid rgb(156 163 175);
flex-shrink: 0;
font-size: 18px;
font-weight: 700;
color: rgb(17 21 26)
}

.d-flex{
    display: flex;
}

.items-center{
    align-items: center;
}

.justify-center{
    justify-content: center;
}

