body {
  background-color: #d40101
}
.trans-bg {
  --ion-background-color: transparent
}
.overflow-none {
  --overflow: hidden
}

/* Set variables for all modes */
:root {
  /* Set the background of the entire app
  --ion-background-color: #ff3700;
*/
  /* Set the font family of the entire app */
  --ion-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
}

ion-header {
  position: absolute;
}

.ion-login-bg {
  --background: url('assets/jordan-whitfield-qODM8pfwRO4-unsplash.jpg') no-repeat center center / cover;
  --background-color: #d40101
}

.ionRow-1, .ionRow-2, .ionRow-3 {
  background: url('assets/marek-piwnicki-HFekBEvysd0-unsplash.jpg') no-repeat center center / cover;
  background-color: #d40101
}

.ionRow-0, .ionRow-1, .ionRow-2, .ionRow-3{
  align-items: center!important;
}

@media (max-width: 640px){
  .ionRow-1, .ionRow-2, .ionRow-3{
    align-items: end!important;
  }
}

.submission-screen-form, .photo-wrap{
  position: relative;
  max-height: 0px;
      transition: max-height .8s cubic-bezier(0, 1, 0, 1) -.1s;
}
.ionRow-2 .submission-screen-form, .ionRow-1 .photo-wrap{
  max-height: 9999px;
     transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
     transition-delay: 0s;
}

.loader-inner {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.cameraa {
  cursor: pointer;
}

.loader-container {
  height: 100%;
  width: 100%;
  left: 0;
  top:0;
  position: absolute;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.79);
}

.submission-screen-form .badge-error {
  font-size: 9px;
  font-weight: 450;
  color: red;
  border-radius: 0;
  background-color: transparent;
  position: absolute;
  left: -7px;
  bottom: 0px;
  z-index: 9;
}

.photo-wrap {
  margin: 0 auto;
  max-width: 620px;
  padding: 10px;
  border: 4px solid #fff;
  border-bottom: 0;
  border-radius: 40px;
  /* border-bottom-right-radius: 40px;
border-bottom-left-radius: 40px; */
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1), 0 10px 30px 0 rgba(0, 0, 0, .2);
}

.photo-wrap .button {
  margin-top: 10px;
}

.item-input.input-error, .item-select.input-error {
  /* border: 1px solid #fea9a9; */
}

/* Set the color on all ion-button elements */
.ion-button {
  --background: #fe1800;
}

.ion-color-white{
  --background: #fff;
}
.ion-color-danger {
  --background: #fe1800;
}

.react-transform-wrapper {
  aspect-ratio: 1 / 1;
}

.canvas-container {
  max-height: 600px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.lower-canvas {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

.upper-canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
}

.canvas-container {
  width: 100% !important;
  height: auto !important;
}

.bg-success {
  background-color: var(--ion-color-success-shade);
  color: var(--ion-color-success-contrast);
}

.bg-error {
  background-color: var(--ion-color-danger-shade);
  color: var(--ion-color-danger-contrast);
}

.bg-pending {
  background-color: var(--ion-color-warning-shade);
  color: var(--ion-color-warning-contrast);
}

ion-item {
  --border-radius: 40px
}

.item-input, .item-native, .item-select {
  /* border: 1px solid #e4e4e4; */
  border-radius: 40px;
}

.ion-row {
  position: relative;
}

.login-screen-form {
  padding-top: 5px;
  max-width: 300px;
  margin: auto;
  border-radius: 30px;
  background: #fff;
}

.login-screen-logo {
  color: #fff
}

.login-screen-form .item-input, .login-screen-form .item-native {
  margin: 10px;
}
.submission-screen-form {
  width: 100%;
  padding-top: 10px;
  max-width: 450px;
  margin: auto;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1), 0 10px 30px 0 rgba(0, 0, 0, .2);
}

.confirmation-screen {
  max-width: 550px;
  margin: auto;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1), 0 10px 30px 0 rgba(0, 0, 0, .2);
}

.confirmation-photo {
  margin-bottom: 20px;
  background: #000;
}

.confirmation-photo img {
  border-radius: 0;
}

.error-input {
  font-size: 12px;
  margin-left: 30px;
  top: -15px;
  position: absolute;
}



.hidden {
  display: none;
}

.r-rds-40 {
  --border-radius: 40px;
  --border-color: transparent
}



.hdr-logo {
  position: fixed;
  left: 0;
  top: 0;
}

ion-footer {
  position: fixed;
  right: 0;
  bottom: 0;
}

.transparent-bg {
  --background: transparent
}

#dot {
  width: 37px;
  height: 37px;
  background-color: rgba(255, 255, 255, 0.41);
  border-radius: 100%;
  position: absolute;
  left: 49.99%;
  margin-left: -20px;
  top: 40px;
}

#dot .ping {
  border: 15px solid rgba(255, 255, 255, 0.84);
  width: 100%;
  height: 100%;
  left: 1px;
  position: relative;
  z-index: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.34);
  border-radius: 100%;
  -webkit-animation-duration: 1.95s;
  animation-duration: 1.95s;
  -webkit-animation-name: sonar;
  animation-name: sonar;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes sonar {
  0% {
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
    transform: scale(1)
  }

  50% {
    transform: scale(2);
    opacity: 0.2
  }

  100% {
    transform: scale(6);
    opacity: 0
  }
}

@keyframes sonar {
  0% {
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
    transform: scale(1)
  }

  50% {
    transform: scale(2);
    opacity: 0.2
  }

  100% {
    transform: scale(6);
    opacity: 0
  }
}

#ion-overlay-1, #ion-overlay-2, #ion-overlay-3, #ion-overlay-4, #submit-modal {
  background-color: rgba(0, 0, 0, 0.72);
}

.status-spinner {
  top: 2px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding: 5px;
  position: relative;
}

.status-tick, .text-success .status-spinner {
  display: none;
}

.text-pending {
  color: #222
}

.text-error {
  color: #ff3232
}

.text-success {
  color: #28cc00
}

.text-success .status-tick {
  display: block;
}

.lower-canvas {
  border: 1px solid #fff;
  background-color: #222;
}

ion-color-primary {
  color: #ee1515;
}

ion-modal#submit-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#submit-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#submit-modal ion-icon {
  margin-right: 6px;
  width: 48px;
  height: 48px;
  padding: 4px 0;
  color: #aaaaaa;
}

ion-modal#submit-modal{
  margin-bottom: 0;
  --border-radius: 15px;
}

/* .item {
--ion-item-background: none;
} */


.submit-wrapper .logo-item-header {
  padding-top: 40px
}

.add-another-btn {
  width: 100%;
  height: 60px;
  margin: 10px 0 0;
  box-shadow: none !important;
}

.add-another-btn .button-native {
  border-radius: 0;
}

.add-another-btn {
  --border-radius: 0;
}

.submit-wrapper .item-native .item-inner {
  border: none
}

.width-1-1 {
  width: 100%;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.photo-wrap .button {
  height: 64px;
  margin-top: 0;
}

.photo-wrap {
  padding: 0;
}

.alert-button-group.sc-ion-alert-ios {
  margin-right: unset;
  -webkit-margin-end: -0.55px;
  margin-inline-end: -0.55px;
}

.alert-button-group.sc-ion-alert-ios {
  margin-right: -0.55px;
  flex-wrap: wrap;
}

.alert-button-group.sc-ion-alert-ios {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.alert-button.sc-ion-alert-ios {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  flex: 1 1 auto;
  min-width: 50%;
  height: 64px;
  background-color: transparent;
  color: var(--ion-color-primary, #3880ff);
  font-size: 17px;
  overflow: hidden;
}

.alert-button:first-child {
  border-right: 1px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.camera-wrapper {
  border-radius: 30px;
  overflow: hidden;
}

.blur .ion-login-bg {
  webkit-filter: blur(1px);
  /* Chrome, Safari, Opera */
  filter: blur(1px);
}

.content {
  width: 100%;
  padding-top: 10px;
  max-width: 450px;
  margin: auto;
  border-radius: 30px;

}
.ion-card-white{
  background: #fff;
  padding-top: 10px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1), 0 10px 30px 0 rgba(0, 0, 0, .2);
}
.ion-card-gray{
  background: rgb(243 244 246);
  padding-top: 10px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1), 0 10px 30px 0 rgba(0, 0, 0, .2);
}

.spinner-wrap{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-spinner {
  --color: #494949;
  width: 70px;
  height: 70px;
}

.filter-button{
  border-radius: 100%;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.filter-button:hover{
box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
}
.filter-button:active{

box-shadow: none !important;
}

