.flex{
  display: flex !important;
}

.justify-center{
  justify-content: center !important;
}

.justify-between{
  justify-content: space-between !important;
}

.align-center{
  align-items: center !important;
}
.flex-nowrap{
  flex-wrap: nowrap !important;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.flex-basis-0{
  flex-basis: 0 0 !important;
}

.mt-md{
  margin-top: 25px !important;
}
.pl-md{
  padding-left: 25px !important;
}

.py-md{
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.px-md{
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.mb-sm{
  margin-bottom: 12px !important;
}

.text-center{
  text-align: center !important;
}
.text-end{
  text-align: end !important;
}

.margin-none{
  margin: 0px !important;
}

.bold{
  font-weight: bold;
}
